@import "/node_modules/toastify-js/src/toastify.css";
@import "~@fortawesome/fontawesome-pro/css/all";
// Fonts



// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
body, html{
    background-color: #fff;
    overflow-x: hidden;
    scroll-behavior: auto !important;
}
textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}
textarea, 
textarea.form-control, 
input.form-control, 
input[type=text], 
input[type=password], 
input[type=email], 
input[type=number], 
[type=text].form-control, 
[type=password].form-control, 
[type=email].form-control, 
[type=tel].form-control, 
[contenteditable].form-control {
    /*change placeholder color*/
    &::placeholder {
        color: #ccc;
    }
}
input:hover {
    border-color: none !important;
    box-shadow: none !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.scrollable-transparent::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
    background-color: #ffffff;
  }
  
  .scrollable-transparent::-webkit-scrollbar {
    width: 1px;
    height: 0px;
    background-color: #ffffff;
  }
  
  .scrollable-transparent::-webkit-scrollbar-thumb {
    background-color: #ffffff;
}
/* scroll-bar width */
::-webkit-scrollbar,
.scrollable::-webkit-scrollbar {
    width: 4px;
}
.scrollable, .scrollable-transparent{
    overflow-y: scroll;
}
/* scroll-bar Track */
::-webkit-scrollbar-track,
.scrollable::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* scroll-bar Handle */
::-webkit-scrollbar-thumb,
.scrollable::-webkit-scrollbar-thumb {
    background: #888;
}

/* scroll-bar Handle on hover */
::-webkit-scrollbar-thumb:hover,
.scrollable::-webkit-scrollbar-thumb:hover {
    background: #c0c0c0;
}
#layout-app{
    min-height: 100dvh;
}
#ridder-feet{
    background-color: #E6E6E6;
    color: #000;
    padding: 3px 0;
    text-decoration: none;
    cursor: pointer;
    transition: .3s;
    font-size: 12px;
    font-weight: bold;
    &:hover,
    &:focus{
        background-color: #CCC;
    }
}
.btn{
    border-radius: 0% !important;
}
.btn-primary{
    color: $white;
    &:hover,
    &:focus{
        background-color: $secondary;
        border: 1px solid $secondary;
    }
}
.btn-success{
    color: $white;
}
.input-group{
    border-radius: 2px;
    &.password-input{
        input{
            border-right: none;
        }
        .input-group-append{
            border-radius: 2px;
            border: 1px solid #ced4da;
            font-size: 1.2em;
            color: #444;
    
        }
    }
    
}
i{
    cursor: pointer;
    &:hover,
    &:focus{
        color: $secondary;
    }
}
.general-padding {
    width: 100%;
    padding: 0 3vw;
}
.general-body-padding{
    width: 100%;
    padding: 0 15vw;
}
.custom-space {
    letter-spacing: 1.68px;
}
.image-input-list{
    margin: 1vh 0 0 0;
    padding: 0;
    list-style: none;
    .image-input-list-item{
        margin: 1vh 5px;
        display: inline-block;
        vertical-align: middle;
        width: min-content;
        
    }
}

.image-container{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px dotted $primary;
    border-radius: 3px;
    cursor: pointer;
    transition: .3s;
    &:hover,
    &:focus,
    &:hover .image-title,
    &:focus .image-title{
        color: $secondary;
    }
}

.image-title{
    font-size: .9em;
    color: $disabled;
    text-align: center;
    transition: .3s;
}
.image-input{
    display: none;
}
.scrollable-transparent::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0);
    background-color: #ffffff;
}

.scrollable-transparent::-webkit-scrollbar
{
    width: 1px;
    height: 0px;
    background-color: #ffffff;
}

.scrollable-transparent::-webkit-scrollbar-thumb
{
    background-color: #ffffff;
}
.Scrollable, .Scrollable2, .scrollable-transparent{
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .fa{
    transition: .3s;
    &:hover{
        transform: scale(1.05);
    }
  }
  .pagination{
    li{
        cursor: pointer;
    }
  }
//center div
.centered{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.gm-style .gmnoprint {
    display: none;
}
.filter-input{
    border: none;
    border-bottom: 1px solid $gray-secondary !important;
    border-radius: 0;
}
.payment-status-0{
    //color orange
    color: #ff9800;
}
.payment-status-1{
    //color green
    color: #4caf50;
}
.payment-status-2{
    //color red
    color: #f44336;
}
.process-status-0{
    //color orange
    color: #ff9800;
}
.process-status-1{
    //color yellow
    color: #ffc107;
}
.process-status-2{
    //color blue
    color: #2196f3;
}
.process-status-3{
    //color red
    color: #f44336;
}
.process-status-4{
    //color purple
    color: #9c27b0;
}
.process-status-5{
    //color green
    color: #4caf50;
}
.pagination{
    .page-item{
        cursor: pointer;
    }
    .page-link{
        select{
            border: none;
            cursor: pointer;
            //remove border on hover
            &:hover, &:active, &:focus{
                border: none;
            }
        }
    }
}